<template>
    <main>
        <page-header title="Editar Contrato Eps"></page-header>
        <div class="container-fluid mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 offset-3">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Codigo</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            v-model="contrato.codigo"
                                            :class="{'is-invalid' : $v.contrato.codigo.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.contrato.codigo.required">Este Campo es requerido</span>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Descripcion</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            v-model="contrato.descripcion"
                                            :class="{'is-invalid' : $v.contrato.descripcion.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.contrato.descripcion.required">Este Campo es requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Eps</label>
                                        <v-select-eps v-model="contrato.id_eps"
                                                      :validator="$v.contrato.id_eps"
                                                      :class="{'is-invalid' : $v.contrato.id_eps.$error}">
                                        </v-select-eps>
                                        <span class="invalid-feedback" v-if="!$v.contrato.id_eps.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            class="form-control form-control-sm"
                                            v-model="contrato.fecha_inicio"
                                            :class="{'is-invalid' : $v.contrato.fecha_inicio.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.contrato.fecha_inicio.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Fecha Final</label>
                                        <input
                                            type="date"
                                            class="form-control form-control-sm"
                                            v-model="contrato.fecha_final"
                                            :class="{'is-invalid' : $v.contrato.fecha_final.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.contrato.fecha_final.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Tipo Afiliación</label>
                                        <select
                                            class="form-control form-control-sm"
                                            v-model="contrato.id_afiliacion"
                                            :class="{'is-invalid' : $v.contrato.id_afiliacion.$error}"
                                        >
                                            <option
                                                v-for="afiliacion in tipos_afiliacion"
                                                :key="afiliacion.id"
                                                :value="afiliacion.id"
                                            >
                                                {{ afiliacion.descripcion }}
                                            </option>
                                        </select>
                                        <span class="invalid-feedback" v-if="!$v.contrato.id_afiliacion.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Tipo Contrato</label>
                                        <select
                                            class="form-control form-control-sm"
                                            v-model="contrato.tipo_contrato"
                                            :class="{'is-invalid' : $v.contrato.tipo_contrato.$error}"
                                        >
                                            <option
                                                v-for="tipo_contrato in tipos_contrato"
                                                :key="tipo_contrato"
                                                :value="tipo_contrato"
                                            >
                                                {{ tipo_contrato }}
                                            </option>
                                        </select>
                                        <span class="invalid-feedback" v-if="!$v.contrato.tipo_contrato.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-4">
                                        <label>Modalidad Atención</label>
                                        <select
                                            class="form-control form-control-sm"
                                            v-model="contrato.modalidad"
                                            :class="{'is-invalid' : $v.contrato.modalidad.$error}"
                                        >
                                            <option
                                                v-for="modalidad in modalidades_atencion"
                                                :key="modalidad"
                                                :value="modalidad"
                                            >
                                                {{ modalidad }}
                                            </option>
                                        </select>
                                        <span class="invalid-feedback" v-if="!$v.contrato.modalidad.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Tarifa</label>
                                        <v-select-tarifa v-model="contrato.id_tarifa"
                                                         :validator="$v.contrato.id_tarifa"
                                                         :class="{'is-invalid' : $v.contrato.id_tarifa.$error}">
                                        </v-select-tarifa>
                                        <span class="invalid-feedback" v-if="!$v.contrato.id_tarifa.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Descuento</label>
                                        <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            v-model="contrato.descuento"
                                            :class="{'is-invalid' : $v.contrato.descuento.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.contrato.descuento.required">
                                            Este Campo es requerido
                                        </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Cuenta Radicado</label>
                                        <v-select-puc v-model="contrato.id_cuenta_radicado"
                                                      :validator="$v.contrato.id_cuenta_radicado">
                                        </v-select-puc>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Cuenta No Radicado</label>
                                        <v-select-puc v-model="contrato.id_cuenta_no_radicado"
                                                      :validator="$v.contrato.id_cuenta_no_radicado">
                                        </v-select-puc>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Cuenta Ingreso</label>
                                        <v-select-puc v-model="contrato.id_cuenta_ingreso"></v-select-puc>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Rubro No Radicado</label>
                                        <v-select-rubro v-model="contrato.id_rubro_no_radicado"
                                                        :validator="$v.contrato.id_rubro_no_radicado">
                                        </v-select-rubro>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Rubro Radicado</label>
                                        <v-select-rubro v-model="contrato.id_rubro_radicado"
                                                        :validator="$v.contrato.id_rubro_radicado">
                                        </v-select-rubro>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <button
                                            type="button"
                                            @click="save()"
                                            class="btn btn-success btn-sm btn-block shadow-sm"
                                        >
                                            <i class="fas fa-save"></i> Guardar Cambios
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2/src/sweetalert2.js";
import VSelectEps from "../../../components/common/VSelectEps.vue";
import VSelectTarifa from "../../../components/common/VSelectTarifa";
import ContratoService from "../../../services/contratosEpsService";
import PageHeader from "../../../components/layouts/content/page-header";
import VSelectPuc from "../../../components/common/VSelectPuc";
import VSelectRubro from "../../../components/common/vSelectRubro";


export default {
    components: {VSelectRubro, VSelectPuc, PageHeader, VSelectEps, VSelectTarifa },
    async created() {
        try{

            this.cargarAfiliaciones();

            const id = this.$route.params.id;

            this.LoaderSpinnerShow();

            const response = (await ContratoService.get(id))?.data;

            this.contrato.id = id;
            this.contrato.id_eps = response.id_eps;
            this.contrato.codigo = response.codigo;
            this.contrato.descripcion = response.descripcion;
            this.contrato.fecha_inicio = response.fecha_inicio;
            this.contrato.fecha_final = response.fecha_final;
            this.contrato.modalidad  = response.modalidad;
            this.contrato.tipo_contrato = response.tipo_contrato;
            this.contrato.id_tarifa = response.id_tarifa;
            this.contrato.descuento = response.descuento;
            this.contrato.id_afiliacion =  response.id_afiliacion;
            this.contrato.id_cuenta_radicado = response.id_cuenta_radicado;
            this.contrato.id_cuenta_no_radicado = response.id_cuenta_no_radicado;
            this.contrato.id_rubro_radicado = response.id_rubro_radicado;
            this.contrato.id_rubro_no_radicado = response.id_rubro_no_radicado;
            this.contrato.id_cuenta_ingreso = response.id_cuenta_ingreso;

            this.LoaderSpinnerHide();

        }catch (e) {
            console.error(e);
            this.LoaderSpinnerHide();
            Swal.fire('Ocurrio un error al procesar la solicitud', '', 'error');
        }
    },
    data() {
        return {
            contrato: {
                codigo: "",
                descripcion: "",
                id_eps: "",
                fecha_inicio: "",
                fecha_final: "",
                valor: "",
                id_afiliacion: "",
                tipo_contrato: "",
                modalidad: "",
                id_tarifa: "",
                descuento: "",
                id_cuenta_radicado : '',
                id_cuenta_no_radicado : '',
                id_rubro_radicado : '',
                id_rubro_no_radicado : '',
                id_cuenta_ingreso : '',
            },
            tipos_afiliacion: [],
            tipos_contrato: ["EVENTO", "CAPITADO"],
            modalidades_atencion: ["ASISTENCIAL", "PYP"],
        };
    },
    validations : {
        contrato: {
            id : '',
            codigo: {required},
            descripcion: {required},
            id_eps: {required},
            fecha_inicio: {required},
            fecha_final: {required},
            id_afiliacion: {required},
            tipo_contrato: {required},
            modalidad: {required},
            id_tarifa: {required},
            descuento: {required},
            id_cuenta_radicado : {required},
            id_cuenta_no_radicado : {required},
            id_rubro_radicado : {required},
            id_rubro_no_radicado : {required}
        },
    },
    methods: {
        async cargarAfiliaciones() {
            let response = await ContratoService.getTiposAfiliacion();
            this.tipos_afiliacion = response.data;
        },
        async save() {
            try {

                this.$v.contrato.$touch();

                if(this.$v.contrato.$invalid) return;

                this.LoaderSpinnerShow();

                await ContratoService.update(this.contrato);

                this.LoaderSpinnerHide();

                Swal.fire({
                    text: "Contrato actualizado con exito",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

            } catch (ex) {
                console.error(ex);
                this.LoaderSpinnerHide();
                Swal.fire({
                    text: "Ocurrio un error, por favor intente nuevamente",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        },
    },
};
</script>

