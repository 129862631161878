<template>
    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container-fluid">
            <div class="page-header-content pt-4">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto mt-4">
                        <h1 class="page-header-title">
                            <div class="page-header-icon">
                                <slot name="page-icon"></slot>
                            </div>
                            {{title}}
                        </h1>
                          <h4 class="page-header-subtitle" > 
                                 {{subtitle}}
                             </h4>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    props  : ['title', 'subtitle']  
}
</script>

